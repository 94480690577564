<template>
  <c-table
    ref="table"
    title="위험형태 목록"
    tableId="riskType"
    :columns="grid.columns"
    :data="grid.data"
    :gridHeight="grid.height"
    :filtering="false"
    :columnSetting="false"
    :usePaging="false"
    :selection="popupParam.type"
    @rowDblclick="rowDblclick"
    rowKey="ramRiskTypeId"
  >
    <!-- 버튼 영역 -->
    <template slot="table-button">
      <q-btn-group outline >
        <c-btn v-if="editable" label="LBLSELECT" icon="check" @btnClicked="select" />
      </q-btn-group>
    </template>
  </c-table>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'kpsr-guideword-pop',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        type: 'multiple',
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'riskTypName',
            field: 'riskTypName',
            label: '위험형태',
            align: 'center',
            style: 'width:100%',
            sortable: true,
          },
        ],
        data: [],
        height: '650px'
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    contentHeight() {
      this.grid.height = (this.contentHeight - 50) + 'px'
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.riskType.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        useFlag: 'Y'
      }
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '위험형태를 선택된 항목이 없습니다.', // 위험형태를 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
